<template>
  <b-modal
      v-model="showUser"
      centered
      title="转移账号"
      size="lg"
      title-class="font-18"
      hide-footer
  >
    <div class="mt-3 text-center">
<!--      <div class="d-flex check-distri mb-3">-->
<!--        <label style="width: 30%">转移到账号：</label>-->
<!--        <div class=" col-sm-10 p-0 flexList">-->
<!--          <input type="text" v-model="form.yhzh" placeholder="转移到账号" class="form-control w-70 h30 "/>-->
<!--        </div>-->
<!--      </div>-->
      <div class="d-flex check-distri mb-3">
        <label style="width: 30%">转移账号电话：</label>
        <div class=" col-sm-10 p-0 flexList">
          <input type="text" v-model="form.yddh" placeholder="账号电话" class="form-control w-70 h30 "/>
        </div>
      </div>
      <div class="d-flex check-distri mb-3">
        <label style="width: 30%">验证码：</label>
        <div class=" col-sm-10 p-0 flexList">
          <input type="text" v-model="form.imgCode" placeholder="验证码" class="form-control w-35 h30"  style="margin-right: 10px"/>
          <img :src="form.img" alt="" style="width: 100px; margin-right: 10px" @click="show">
          <button type="button" :disabled="buttonInfo != defaultBtnInfo" class="btn btn-info h30 w-md mr-3" @click="sendCode">
            {{buttonInfo != defaultBtnInfo?('剩余' + buttonInfo + '秒'):buttonInfo}}
          </button>
        </div>
      </div>
      <div class="d-flex check-distri mb-3">
        <label style="width: 30%">短信验证码：</label>
        <div class=" col-sm-10 p-0 flexList">
          <input type="text" v-model="form.code" placeholder="短信验证码" class="form-control h30"  style="margin-right: 10px; width: 52%"/>
          <button type="button" class="btn btn-danger h30 w-md mr-3" @click="transferAccount">确定转移</button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {sendPhoneCode} from "@/api/index_user";
import {getcode} from "@/api/index_user";
import {transferAccount} from "@/api/admin/exam/checkPower";
import {loginJump} from "@/api";
import {getNowExam, getNowExamHandle} from "../../../../api/admin/exam/examRecord";
export default {
  name: "transfer-account",
  data(){
    return {
      showUser: false,
      form: {},
      defaultBtnInfo: "获取验证码",
      buttonInfo: "获取验证码",
    }
  },
  components:{
  },
  methods: {
    show(){
      getcode().then((res) => {
        if (res.status) {
          this.form.uuid = res.data.uuid;
          this.form.img = res.data.img;
          this.$forceUpdate()
          this.showUser = true
        }
      });
    },
    getVerify(){
      if (!this.form.imgCode){
        this.$notify.warning("请输入图形验证码！")
        return false
      }
      if (!this.form.yddh || this.form.yddh.length != 11){
        this.$notify.error("请输入正确格式的手机号码！")
        return false
      }
      return true
    },
    sendCode(){
      if (!this.getVerify()){
        return;
      }
      var formData = {
        mobilePhone: this.form.yddh,
        code: this.form.imgCode,
        uuid: this.form.uuid,
      };
      sendPhoneCode(formData).then(res=>{
        if (res.status){
          this.buttonInfo = 60
          let timeout = setInterval( ()=> {
            this.buttonInfo--;
            if (this.buttonInfo <= 0){
              clearInterval(timeout);
              this.buttonInfo = this.defaultBtnInfo
              this.$forceUpdate()
            }
          }, 1000)
        }
      })
    },
    transferAccount(){
        if (!this.getVerify()){
          return;
        }
        this.$confirm(`转移后该账号将无法登录, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          transferAccount(this.form).then(res => {
            if (res.status){
              this.$confirm(`转移成功，原账号为【${res.data.yhzh}】，请保存`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(()=>{
                  this.setStore("accessToken", res.data.token);
                  this.setStore("userInfo", res.data);
                  getNowExam().then(res=>{
                    if (res.status){
                      this.$message({
                        type: 'success',
                        message: '转移成功!'
                      });
                      window.location.reload()
                    }
                  })
              })
            }
          })
        })
    },
  }
}
</script>

<style scoped>

</style>
