import {getRequest, patchRequest,putRequest} from "@/libs/axios";

// 机构用户
export const updatePhone = (phone)=>{
    return patchRequest("/yethan/UserManagement/self/phone", {phone: phone})
}

export const updatePassword = (oldPassword, nowPassword)=>{
    return patchRequest("/yethan/UserManagement/self/password", {oldPassword: oldPassword, nowPassword: nowPassword})
}

export const updateEmail = (email)=>{
    return patchRequest("/yethan/UserManagement/self/email", {email: email})

}

export const getSelfDetails = ()=>{
    return getRequest("/yethan/UserManagement/self")
}

// 机构用户信息修改
export const editInstiUser = params => {
  return putRequest("/yethan/UserManagement/info", params);
}