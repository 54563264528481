import {
  postNoAuthRequest,
  getRequest,
  postRequest,
  getNoAuthRequest,
  uploadFile
} from "@/libs/axios";

/**/

// 用于发送获取图片验证码
export const getcode = params => {
  return getNoAuthRequest("/yethan/code", params);
};

// 用于发送获取手机验证码
export const sendPhoneCode = params => {
  return getNoAuthRequest("/yethan/sendPhoneCode", params);
};

// 用于注册发送获取手机验证码
export const sendPhoneCodeNotUse = params => {
  return getNoAuthRequest("/yethan/sendPhoneCodeNotUse", params);
};

// 用于使用账号密码登录
export const userLogin = params => {
  return postNoAuthRequest("/yethan/login", params);
};
// 用于使用手机密码登录
export const phoneLogin = params => {
  return postNoAuthRequest("/yethan/phoneLogin", params);
};
// 用于登出
export const loginOut = params => {
  return getRequest(`/yethan/loginOut`, params);
};
// 注册
export const userRegister = params => {
  return postNoAuthRequest("/yethan/register", params);
};
// 校验算数验证码
export const checkCode = params => {
  return getRequest("/yethan/checkCode", params);
};
// 找回密码
export const findPassword = params => {
  return postNoAuthRequest(`/yethan/findPassword`, params);
};

//考生用户找回密码校验短信验证码
export const checkMobileCode = params => {
  return getNoAuthRequest(`/yethan/checkMobileCode`, params);
};


//考生用户找回密码发送短信
export const findPasswordPhoneCode = params => {
  return getNoAuthRequest(`/yethan/findPasswordPhoneCode`, params);
};
//查询文件类型
export const  getFileType= (fileId) => {
  return getRequest(`/yethan/file/type/${fileId}`);
};

//上传文件接口
export const uploadFiles = (module, file,params) => {
  return uploadFile("/yethan/file/uploadOne?module=" + module, file,params);
}
//上传文件限制参数
export const getUploadLimit = params => {
  return postRequest(`/yethan/file/getUploadLimit`, params, false);
}
//账号赎回申请提交
export const accountBack = params => {
  return postRequest("/yethan/userAccountBack/apply", params);
};

// 系统参数
 export const  initSystem= (params) => {
    return getRequest("/yethan/sysConfig/webCommon", params,false);
};

// 手机验证码是否过期
export const  codeExpired= (params) => {
  return getRequest("/yethan/checkPhoneCode", params,false);
};

// 全局监听Enter事件，并且执行传入的函数
export const listenEnterEv = function (evFunc) {
  document.onkeydown = function (ev) {
    if (ev.key === "Enter") {
      evFunc();
    }
  }
}