<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
  .msgValInput {
    font-size: 12px;
  }
</style>
<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    getSelfDetails,
    editInstiUser
  } from "@/api/admin/user/self";
  import {setStore} from "@/libs/storage";
  import transferAccount from '@/views/pages/user/components/transfer-account'
  /**
   * PersonData
   */
  export default {
    components: {
      Layout,
      PageHeader,
      transferAccount,
    },
    data() {
      return {
        title: "机构用户个人资料",
        items: [{
            text: "资料完善"
          },
          {
            text: "基本信息",
            active: true
          }
        ],
        userDetails: {},
        userInfo: {}

      };
    },
    methods: {
      getUserDetails() {
        getSelfDetails().then(res => {
          if (res.status) {
            this.userDetails = res.data
            this.userDetails.yhjs = this.getUserType(this.userInfo.roleNum)
          }
        })
      },
      submit() {
        let data = JSON.parse(JSON.stringify(this.userDetails))
        if (!data || data.yddh.length != 11) {
          this.$message({
            type: 'error',
            message: '请正确填写手机号码',
          })
          return false
        }
        if (!data || data.xm == this.userInfo.yhzh || data.xm == '') {
          this.$message({
            type: 'error',
            message: '请填写真实姓名',
          })
          return false
        }
        editInstiUser(data).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '信息修改成功!',
            })
            // 更新姓名、手机号
            this.userInfo['xm'] = data.xm
            this.userInfo['yddh'] = data.yddh
            setStore("userInfo", this.userInfo);
            this.$forceUpdate()
            getSelfDetails().then(res => {
              if (res.status) {
                setStore("userDetails", JSON.stringify(res.data))
              }
              window.location.reload()
            })
          } else {
            if (res.data.message == "电话已经存在"){
                this.$confirm(`您可以尝试转移到该电话的账号, 是否开始账号转移?`, '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(()=>{
                    this.$refs.transferAccount.show()
                })
            }
          }
        })
      },
      transferAccount(){

      }
    },
    mounted() {
      this.userInfo = JSON.parse(this.getStore('userInfo'))
      this.getUserDetails()

    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px;">
          <div class="card-body ">
            <h4 class="card-title pb-3 border-dash" style="display: flex; justify-content: space-between">
              <span class="mr-2">基本资料</span>
              <button class="btn btn-info h35" style="text-align: center" @click="$refs.transferAccount.show()">账号转移</button>
            </h4>
            <div class="outerCotent p-4">
              <input type="hidden" class="msgValInput" value="" name="sid">
              <div class="msgContent flexList fs-xs">
                <div class="flexList w-50 msgLabel">
                  <div class="msgName">姓名</div>
                  <div class="msgVal  flexList">
                    <input type="text" class="form-control  msgValInput mr-2" v-model="userDetails.xm">
                    <!-- <div class="w-40 line1">
                      {{userDetails.xm}}
                    </div> -->
                    <b style="color:red;" v-if="!userDetails.xm">必填</b>
                  </div>
                </div>
                <div class="flexList w-50 msgLabel">
                  <div class="msgName">联系电话</div>
                  <div class="msgVal  flexList">
                    <input class="msgValInput  form-control  mr-2" name="lxdh" v-model="userDetails.yddh">
                    <b style="color:red;" v-if="!userDetails.yddh">用于登录，请正确填写</b>
                  </div>
                </div>
                <div class="flexList w-50 msgLabel">
                  <div class="msgName">身份证号</div>
                  <div class="msgVal  flexList">
                    <input type="text" class="form-control  msgValInput" v-model="userDetails.sfzjh">
                    <!-- <div class="line1">{{userDetails.sfzjh}}</div> -->
                  </div>
                </div>
                <div class="flexList w-50 msgLabel">
                  <div class="msgName ">出生日期</div>
                  <div class="msgVal flexList">
                    <el-date-picker v-model="userDetails.csrq" type="date" class="w-60" size="small" placeholder="选择日期"
                      value-format="yyyyMMdd">
                    </el-date-picker>
                  </div>
                </div>
                <div class="flexList w-50 msgLabel">
                  <div class="msgName">机构</div>
                  <div class="msgVal  flexList">
                    <input type="text" class="form-control  msgValInput" v-model="userDetails.jgmc" >
                    <!-- <div class="msgValInput line1">
                      {{userDetails.jgmc}}
                    </div> -->
                  </div>
                </div>
                <div class="flexList w-50 msgLabel">
                  <div class="msgName">部门</div>
                  <div class="msgVal  flexList">
                    <input type="text" class="form-control  msgValInput" v-model="userDetails.bmmc">
                    <!-- <div class="msgValInput line1">
                      {{userDetails.bmmc}}
                    </div> -->
                  </div>
                </div>

                <div class="flexList w-50 msgLabel">
                  <div class="msgName">职务</div>
                  <div class="msgVal flexList">
                    <input type="text" class="form-control  msgValInput" v-model="userDetails.zw">
                    <!-- <div class="msgValInput line1">
                      {{userDetails.zw}}
                    </div> -->
                  </div>
                </div>


                <!-- <div class="flexList w-50 msgLabel">
                  <div class="msgName">电子邮件</div>
                  <div class="msgVal  flexList">
                    <input type="text" class="form-control  msgValInput" v-model="userDetails.dzxx">

                  </div>
                </div>
                -->

                <div class="flexList w-50 msgLabel">
                  <div class="msgName flexList justify-content-center">办公地址</div>
                  <div class="msgVal flexList ">
                    <input type="text" class="form-control  msgValInput" v-model="userDetails.bgdz" value="四川省成都市青羊区SS大道143栋113号
">
                  </div>
                </div>

              </div>

            </div>
            <div class=" text-center " style="height: 50px;">
              <button class="btn btn-info h35 sub-btn" @click="submit">提交信息</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transferAccount ref="transferAccount"></transferAccount>
  </Layout>
</template>
